.root {
	@apply tw-flex tw-items-center tw-gap-[18px];
}

.rootTight {
	@apply tw-gap-[5px];
}

.chatIsUnavailable {
	@apply tw-mt-[19px];
}

.labelIsChatUnavailable {
	@apply tw-text-gray;
}
