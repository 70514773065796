.list-views-toolbar {
	@apply tw-flex tw-flex-grow tw-justify-end tw-items-center;

}

.toolbar-text {
	@apply sm:tw-hidden;
	margin-right: 10px;
}

.toolbar-button {
	@apply tw-flex tw-justify-center tw-items-center tw-relative tw-mr-1;
	width: 35px;
	height: 35px;

	&:last-of-type {
		@apply tw-mr-0;
	}

	&.smallTileView {
		@apply tw-hidden;

		@screen md {
			@apply tw-flex;
		}
	}

	&.selected {
		&::after {
			@apply tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0;
			content: '';
			border-top: 3px solid theme('colors.gray.DEFAULT');
			bottom: -5px;
			z-index: 108;
		}
	}

	&:not(.selected) {
		&:hover,
		&:focus {
			&::after {
				width: 100%;
			}
			&::before {
				opacity: 1;
			}
		}
		&::after {
			@apply tw-h-0 tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
			content: '';
			transition: width 0.2s ease-in-out;
			border-top: 3px solid theme('colors.gray.DEFAULT');
			bottom: -5px;
			z-index: 108;
		}
	}
}
