.introText {
	@apply tw-mb-6 md:tw-mb-[15px] tw-text-gray;
}

.introTextChatIsUnavailable {
	@apply tw-mb-0 sm:tw-mb-2;
}

.contactOptions {
	@apply tw-flex tw-flex-col tw-gap-6 md:tw-gap-[20px] tw-pb-[24px] md:tw-pb-[14px];
}

.contactOptionsChatIsUnavailable {
	@apply tw-gap-[20px] md:tw-gap-[24px];
}

.appointmentSection {
	@apply tw-border-t tw-border-t-gray-400 tw-pt-5 tw-mb-[36px];
}

.appointmentSectionIntroText {
	@apply tw-mb-[18px];
}

.designAssociateSection {
	@apply tw-border-t tw-border-t-gray-400 tw-pt-5 tw-mb-[24px];
}
