.root {
	@apply tw-flex tw-items-center tw-gap-[6px];
}

.statusLabel {
	@apply tw-text-2xs tw-text-gray-300;
}

.status {
	@apply tw-w-2 tw-h-2 tw-bg-red tw-rounded-full;
}
