.hover-style {
	path {
		stroke: theme('colors.gray.DEFAULT')
	}
	.outline {
		fill: theme('colors.white.DEFAULT');
	}
	.solid {
		fill: theme('colors.gray.DEFAULT');
	}
}

.large-tiles {
	width: 22px;
	height: 22px;

	path {
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke: theme('colors.blue.DEFAULT');
	}
	
	.outline{
		stroke-width: 1;
		fill: theme('colors.white.DEFAULT');
	};
	.solid {
		stroke-width: 0;
		fill: theme('colors.blue.DEFAULT');
	};

	&:focus, &:hover, &:global(.selected) {
		@extend .hover-style;
	}
}

:focus, :hover {
	> .large-tiles {
		@extend .hover-style;
	}
}