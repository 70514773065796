.infoSection {
	padding-top: 45px;
}

.formIntro {
	@apply tw-text-lg;
}

.formIntro, .createAccountBlurb {
	margin: 8px 0 14px;
}

.createAccountBlurb {
	@apply tw-font-semibold tw-text-sm tw-mt-3;
}

.infoList {
	@apply tw-list-outside tw-mt-3 tw-leading-8 tw-ml-6;
}

.spinnerContainer {
	@apply tw-relative;
	height: 500px;
}
