.root {
	@apply tw-mb-[65px];
}

.links {
	@apply tw-flex tw-flex-col tw-gap-9 tw-pt-4 sm:tw-pt-[25px];
}

.inStoreLinkLabel {
	@apply tw-hidden md:tw-block;
}

.inStoreLinkLabelMobile {
	@apply md:tw-hidden;
}
