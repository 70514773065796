.hover-style {
	rect {
		// stroke: theme('colors.gray.DEFAULT');
		fill: theme('colors.gray.DEFAULT');
	}
}

.detailView {
	width: 35px;
	height: 35px;

	rect {
		// stroke-width:1;
		// stroke-linecap: butt;
		// stroke-linejoin: miter;
		// stroke: theme('colors.blue.DEFAULT');
		fill: theme('colors.blue.DEFAULT');
	}

	&:focus, &:hover, &:global(.selected) {
		@extend .hover-style;
	}
}

:focus, :hover {
	> .detailView {
		@extend .hover-style;
	}
}
