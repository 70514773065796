.hover-style {
	path {
		stroke: theme('colors.gray.DEFAULT')
	}
}

.small-tiles {
	width: 35px;
	height: 35px;
	
	path {
		stroke-width:1;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke: theme('colors.blue.DEFAULT');
		fill:#fff
	}

	&:focus, &:hover, &:global(.selected) {
		@extend .hover-style;
	}
}

:focus, :hover {
	> .small-tiles {
		@extend .hover-style;
	}
}