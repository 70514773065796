.root {
	@apply tw-grid tw-grid-cols-4 tw-gap-x-2.5 md:tw-items-center;
}

.heading {
	@apply tw-font-semibold;
}

.imageColumn {
	@apply tw-col-span-1;
}

.contentColumn {
	@apply tw-col-span-3;
}

.externalLinkIcon {
	@apply tw-ml-2.5;
}

.linkLabel {
	@apply tw-inline md:tw-inline-flex;
}
